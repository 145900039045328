import { forwardRef, memo, useContext, useEffect, useMemo, useState } from 'react';
import PropTypes, { type Validator } from 'prop-types';
import isNil from 'lodash/isNil';
// EmPath UI Components
import { type GetComponentProps } from '@empathco/ui-components/src/helpers/types';
import { paramsDiffer } from '@empathco/ui-components/src/helpers/pagination';
// local imports
import { SkillLevel, SKILL_LEVEL_MIN } from '../models/skill';
import { GlobalContext } from '../context/global';
import { EmployeesWithSkillParams, SupervisorContext } from '../context/supervisor';
import EmployeeCard from '../v3/EmployeeCard';
import EmployeeTeamCard from '../v3/EmployeeTeamCard';
import CardsGrid from '../v3/CardsGrid';
import PaginationControls from '../v3/PaginationControls';

type EmployeesWithSkillListProps = {
  skillId: string;
  asTeams: boolean;
  managerId?: string | null;
  level?: SkillLevel | null;
  stateId?: number | null;
  countryId?: number | null;
}

const EmployeesWithSkillListPropTypes = {
  skillId: PropTypes.string.isRequired,
  asTeams: PropTypes.bool.isRequired,
  managerId: PropTypes.string,
  level: PropTypes.number as Validator<SkillLevel>,
  stateId: PropTypes.number,
  countryId: PropTypes.number
};

const EmployeesWithSkillList = forwardRef<HTMLDivElement, EmployeesWithSkillListProps>(({
  skillId,
  asTeams,
  managerId,
  level = SKILL_LEVEL_MIN,
  stateId,
  countryId
}, ref) => {
  const { paths: { supvEmplPath } } = useContext(GlobalContext);
  const {
    mgrEmployeesWithSkill: { data: employees, count, pending: employeesPending, failed: employeesFailed, params },
    requireEmployeesWithSkill,
    mgrTeamsWithSkill: { data: teams, pending: teamsPending, failed: teamsFailed },
    requireTeamsWithSkill
  } = useContext(SupervisorContext);

  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState<number>();

  useEffect(() => {
    if (asTeams) {
      requireTeamsWithSkill?.({ skill_id: skillId, manager_id: managerId });
    } else if (requireEmployeesWithSkill && !isNil(pageSize)) {
      const newParams: EmployeesWithSkillParams = {
        skill_id: skillId,
        level,
        state_id: stateId,
        country_id: stateId ? undefined : countryId,
        limit: pageSize
      };
      let curPage = currentPage;
      if (paramsDiffer(params, newParams)) {
        curPage = 1;
        setCurrentPage(1);
      }
      requireEmployeesWithSkill({
        ...newParams,
        offset: pageSize * (curPage - 1)
       });
     }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    // only filter values are monitored here (i.e. ignoring `params` changes)
    asTeams, skillId, managerId, level, stateId, countryId, currentPage, pageSize,
    requireTeamsWithSkill, requireEmployeesWithSkill
  ]);

  const pending = asTeams ? teamsPending && !teams : employeesPending && !employees;
  const failed = asTeams ? teamsFailed : employeesFailed;

  const values = useMemo(() => asTeams ? undefined : { level, count }, [asTeams, count, level]);

  const componentProps: Partial<GetComponentProps<typeof EmployeeCard>> = useMemo(() => ({
    withSkills: true,
    route: supvEmplPath
  }), [supvEmplPath]);

  return asTeams ? (
    <CardsGrid
        ref={ref}
        items={teams}
        title="skill.teams_list_title"
        values={values}
        variant="shady"
        pending={pending}
        failed={failed}
        withoutTopPadding
        notFoundMessage="employees.no_teams"
        component={EmployeeTeamCard}
        ComponentProps={undefined as unknown as Partial<GetComponentProps<typeof EmployeeTeamCard>>}
    />
  ) : (
    <CardsGrid
        ref={ref}
        items={employees}
        title="skill.employees_list_title"
        notFoundMessage="employees.no_direct_reports"
        values={values}
        variant="shady"
        pending={pending}
        failed={failed}
        withoutTopPadding
        component={EmployeeCard}
        ComponentProps={componentProps}
        pagination={(
          <PaginationControls
              settingsId="skill_employees"
              loaded={Boolean(employees)}
              pending={pending}
              loading={pending}
              total={count}
              currentPage={currentPage}
              onPageSelected={setCurrentPage}
              onPageSize={setPageSize}
              disabled={pending || failed}
          />
        )}
    />
  );
});

EmployeesWithSkillList.displayName = 'EmployeesWithSkillList';

EmployeesWithSkillList.propTypes = EmployeesWithSkillListPropTypes;

export default memo(EmployeesWithSkillList);
